/*  ==========================================================================
    QUICK REQUEST V1
    ========================================================================== */

@mixin quick-request--v1() {
  background-color: $clr__ci--quaternary !important;
  bottom: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: $unit__zi--quick-request;
  transition: height 0.75s;
  > .bn-grid__row {
    height: 100%;
  }
  .bn-quick-request__title,
  .bn-quick-request__form {
    align-items: center;
    display: flex;
  }
  .bn-quick-request__title {
    color: $clr__typo--primary;
    display: none;
    font-size: 2.0em;
    max-width: 15%;
    text-transform: uppercase;
    > div {
      width: 100%;
    }
  }
  .bn-quick-request__form {
    @include quick-request--horizontal();
  }
  &.bn-quick-request--small {
    width: calc(100% - #{$unit__wdt--header-v3-sidebar-desktop-large});
    .bn-quick-request__title {
      display: none;
    }
  }
  &.bn-quick-request--visible {
    background-color: 1px solid $clr__ci--quaternary;
    color: white;
    height: $unit__hgt--quick-request-v1;
    overflow: visible;
    transition: height 0.75s;
  }
  #bn_quickrequest_horizontal {
    background-color: $clr__ci--quaternary !important;
    .bn-form__column--requiredFields {
      color: white !important;
      bottom: 0px !important;
      @media #{$mq__tablet} {
        bottom: -8px !important;
      }
    }
    .bn-iconBtn {
      border-radius: 0px !important;
      color: white !important;
      border-color: white !important;
      &:hover {
        background-color: #ffffff66 !important;
      }
    }
    .bn-form__checkbox input:checked~.bn-form__label .bn-form__helper {
      background-color: transparent !important;
    }
    .bn-form-quickrequest-container {
      background-color: $clr__ci--quaternary !important;
    }
    .bn-form-quickrequest {
      .bn-form__column--submit {
        min-width: auto;
        flex-basis: 0;
        padding: 0px 5px !important;
        display: inline-table;
      }
    }
    input, select, input.has-value, input.has-value~.bn-form__label {
      border-bottom-color: white !important;
      color: white !important;
    }
    input~.bn-form__label, select~.bn-form__label {
      color: white !important;
    }
    .bn-form__groupIcon {
      color: white !important;
    }
    .bn-form__select-wrapper:after {
      border-color: white !important;
    }
    .bn-childrenContainer {
      background-color: $clr__ci--quaternary !important;
      .bn-form__group.bn-form__select-wrapper select {
        background-color: $clr__ci--quaternary !important;
      }
    }
    .ractive--childrenTemplate .bn-from__select {
      border-bottom-color: #ffffff!important;
    }
    .bn-form__button {
      color: #fff!important;
      font-weight: 700 !important;
      min-width: auto!important;
      width: auto!important;
      padding: 15px 25px;
      letter-spacing: .8px;
      background-color: $clr__cta--primary !important;
      border: none;
      align-items: flex-start;
      text-decoration: none;
      font-family: $typo__ff--primary;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      color: white;
      &:hover {
        background-color: $clr__cta--primary-hover !important;
        color: white !important;
        border: none;
      }
      &.bn-form__button--v1 {
        background-color: $clr__cta--secondary!important;
        &:hover {
          background-color: $clr__cta--secondary-hover!important;
        }
      }
    }
  }
  #bn_request-MNBH {
    .bn-form__button-container.bn-form__submit {
      .bn-form__button {
        color: #fff!important;
        font-weight: 700 !important;
        min-width: auto!important;
        width: auto!important;
        padding: 15px 25px;
        letter-spacing: .8px;
        background-color: $clr__cta--primary !important;
        border: none;
        align-items: flex-start;
        text-decoration: none;
        font-family: $typo__ff--primary;
        font-size: 14px;
        line-height: 18px;
        display: inline-block;
        &:hover {
          background-color: $clr__cta--primary-hover !important;
          color: white !important;
          border: none;
        }
      }
    }
  }

          /*
           * RESPONSIVE BEHAVIOR
           */

  // desktop medium
  @media #{$mq__desktop--medium} {
    &.bn-quick-request--small {
      width: calc(100% - #{$unit__wdt--header-v3-sidebar});
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-quick-request__title {
      display: none;
    }
  }
  // tablet
  @media #{$mq__laptop} {
    display: none;
  }
}