/*  ==========================================================================
    SIDELINKS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__sidelinks-icons: $clr__ci--secondary;
$module-clr__sidelinks-title: $clr__seaside--blue;
$module-clr__sidelinks-inactive: $clr__ci--secondary;

$module-clr__sidelinks-icons-cta: $clr__cta--primary;
$module-clr__sidelinks-title-cta: $clr__cta--primary;
$module-clr__sidelinks-inactive-cta: $clr__cta--secondary;

/*
 * MODULE
 */
body:not(.pace-done) .dna-sidelinks{
  opacity: 0;
}
body.vista-half {
  .dna-sidelinks {
    bottom: 25%;
  }
}
.dna-sidelinks {
  position: fixed;
  right: 0;
  bottom: 20%;
  z-index: 100;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .dna-sidelink {
    display: flex;
    margin-bottom: 6px;
    transition: 0.3s;
    transform: translateX(285px);
    pointer-events: all;
    &.dna-sidelink--visible {
      > * {
        border: none !important;
      }
    }
    &:hover + .bn-booking-annot {
      opacity: 1;
      width: 500px;
      display: flex;
      justify-content: flex-end;
      transition: opacity 0.3s;
    }

    + .bn-booking-annot {
      opacity: 0;
    }
    .dna-sidelink-title {
      transition: 0.3s;
      color: $module-clr__sidelinks-inactive;
      background-color: $module-clr__sidelinks-inactive;
      width: 300px;
      min-height: 56px;
      display: flex;
      align-items: center;
      font-size: 24px;
      box-sizing: border-box;
      padding: 10px 0 10px 15px;
      font-family: $typo__ff--secondary;
      border: 1px solid;
      border-right: none;
      position: relative;
      span {
        z-index: 2;
        line-height: 1;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
    .dna-sidelink-icon {
      width: 35px;
      font-size: 24px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      border: 1px solid;
      color: $module-clr__sidelinks-icons;
    }
    &.dna-sidelink--cta {
      .dna-sidelink-title {
        background-color: $module-clr__sidelinks-inactive-cta;
        color: $module-clr__sidelinks-inactive-cta;
      }
      .dna-sidelink-icon {
        border-color: $module-clr__sidelinks-inactive-cta;
        color: $module-clr__sidelinks-icons;
      }
      &:hover, &.dna-sidelink--visible {
        .dna-sidelink-icon {
          border-color: $module-clr__sidelinks-icons-cta;
          color: $module-clr__sidelinks-icons;
        }
        .dna-sidelink-title {
          color: $module-clr__sidelinks-title;
          border-color: $module-clr__sidelinks-inactive-cta;
        }
      }
      &.dna-sidelink--visible:hover {
        .dna-sidelink-title {
          &::before {
            content: "";
            background-color: rgba($module-clr__sidelinks-title-cta, 0.05);
          }
        }
      }
    }
    &:hover, &.dna-sidelink--visible {
      transform: translateX(0px);
      .dna-sidelink-title {
        color: $module-clr__sidelinks-title;
        border-color: $module-clr__sidelinks-inactive;
        background-color: white;
      }
    }
    &.dna-sidelink--visible:hover {
      .dna-sidelink-title {
        &::before {
          content: "";
          background-color: rgba($module-clr__sidelinks-title, 0.05);
        }
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  @media #{$mq__tablet} {
    display: none;
  }
}

