/*  ==========================================================================
    GENERAL
    Useful global helpers.

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// aspect ratio
@mixin aspect-ratio($width,$height) {
    height: 0;
    padding-top: ($height / $width) * 100%;
}

// clearfix
@mixin clearfix() {
    *zoom: 1;
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// create table
@mixin display--table() {
    display: table;
    > * {
        display: table-row;
        height: 100%;
        width: 100%;
        > * {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

// reset table
@mixin display--table-reset() {
    display: initial;
    > * {
        display: initial;
        height: auto;
        width: auto;
        > * {
            display: initial;
            vertical-align: initial;
        }
    }
}

// loader
@mixin loader($clr,$size) {
    color: $clr;
    display: inline-block;
    font-size: $size;
    height: $size;
    line-height: $size;
    text-align: center;
    width: 100%;
    .bn-icon {
        animation: rotate normal 1s infinite linear;
        display: inline-block;
    }
}

// scrollbar
@mixin scrollbar($clr,$size) {
    &::-webkit-scrollbar {
        height: $size;
        width: $size;
    }
    &::-webkit-scrollbar-track {
        background: $clr__black;
    }
    &::-webkit-scrollbar-thumb {
        background: $clr;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba($clr, 0.9);
    }
}

// waypoint
@mixin waypoint() {
    height: 1px;
    position: relative;
    visibility: hidden;
    width: 1px;
    z-index: $unit__zi--mixins-waypoint;
}
@mixin top-references-bar() {

    display: none;
    @media #{$mq__tablet} {
        display: flex;
    }
    a.bn-top-references__container {
        background-color: $clr__ci--primary;
        height: 59px;
        width: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 10px;

        border-left: 1px solid #fff;
        color: #fff;
        &:hover {
            background-color: $clr__ci--secondary;
        }
        &:first-child {
            border-left: 0px;
        }
        .bn-top-references__item {
            font-size: 22px;
        }
        .bn-top-references__description {
            text-align: center;
            font-size: 10px;
            text-transform: uppercase;
        }
        &.bn-top-references__container--big {
            flex: 1;
            flex-direction: row;
            .bn-top-references__item {
                margin-right: 5px;
            }
            .bn-top-references__description {
                font-size: 16px;
            }
            &.bn-top-references__container--no-icon {
                .bn-top-references__item {
                    display: none
                }
            }
        }
    }
    .bn-top-references__item--trigger {
        display: none;
    }
}
@mixin top-references-colors(){
    .bn-top-references__container {
        &.bn-top-references__container--cSecondary {
            background-color: $clr__ci--secondary !important;
            &:hover {
                background-color: $clr__ci--primary !important;
            }
        }

        &.bn-top-references__container--cCallToAction {
            background-color: $clr__cta--primary !important;
            &:hover {
                background-color: $clr__cta--primary-hover !important;
            }
        }
        &.bn-top-references__container--cCallToActionSecondary {
            background-color: $clr__cta--secondary !important;
            &:hover {
                background-color: $clr__cta--secondary-hover !important;
            }
        }
        &.bn-top-references__container--cWhite {
            color: $clr__ci--primary;
            background-color: $clr__white !important;
            &:hover {
                color: $clr__white;
                background-color: $clr__ci--primary !important;
            }
        }
    }
}