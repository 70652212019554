/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity

$clr__seaside--blue: #003E54;
$clr__seaside--dark-blue: #063141;
$clr__seaside--darker-blue: #042734;
$clr__seaside--darkest-blue: #03202b;
$clr__seaside--newblue: #023D54;

$clr__ci--primary: $clr__seaside--blue;
$clr__ci--secondary: #A39584;
$clr__ci--tertiary: #F2F2F2;
$clr__ci--quaternary: #8B7F70;

// typography
$clr__typo--primary: #626262;
$clr__typo--secondary: #FFFFFF;

// call to action
$clr__cta--primary: #FF6200;
$clr__cta--primary-hover: #FF7824;

$clr__cta--secondary: #fd9c60;
$clr__cta--secondary-hover: #FDBC94;

// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * SPECIFIC
 */

$clr__page: $clr__white;
$clr__progress-bar: $clr__seaside--blue;
$clr__scrollbar: $clr__ci--secondary;

/*
 * OPTIONS
 */
//Bei true haben Elemente einen Border, bei false einen schatten
$tgl__use-border: true;
//Derzeit nur bei bn-top-references footer verbaut

/*
 * CUSTOM
 */

