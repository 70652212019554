@import url("https://cdn.curator.io/3.0/css/curator.css");
@import url("https://cdn.bnamic.com/smw/templates/v1/index.css");

.dna-socialmediawall {
  background: $clr__white;
  padding: 60px 0;
  margin: 0 10px;

  &.dna-home {
    min-height: 100vh;
  }

  &__title {
    font-size: 42px;
    font-family: $typo__ff--primary;
    font-weight: bold;
  }

  &__wall {
    margin: 50px -12px -24px;
  }

  &__linkwrap {
    display: flex;
    justify-content: center;
  }

  &__link,
  .ctr-filter {
    font-size: 2em;
  }
  .crt-load-more a{
    background-color: $clr__white;
    border-radius: 100px;
    padding: 15px 35px;
    border-color: $clr__ci--primary;
    color: $typo__ff--primary;
    font-weight: bold;
    font-family: $typo__ff--primary;
    font-size: 16px;
    position: relative;
    z-index: 1;
    border: 1px solid;
    text-decoration: none;
    &:hover {
      background-color: $clr__ci--primary;
      color: $clr__ci--primary;
      text-decoration: none;
    }
  }
}

@media (max-width: 1024px) {
  .dna-socialmediawall {
    padding: 60px 120px;
    &__title {
      font-size: 26px;
    }
  }
}

@media (max-width: 767px) {
  .dna-socialmediawall {
    padding: 60px 0;
    &__title {
      font-size: 26px;
    }
  }
}