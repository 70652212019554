.bn-booking-annot{
  &__container {
    background-color: rgba(0, 62, 84, 0.7);
    color: white;
    padding: 10px 5px 10px 15px;
    display: flex;
    font-size: 15px;
    max-width: 500px;
    position: absolute;
    border: 1px solid #A39584;
    backdrop-filter: blur(3px);
    border-right: 0;
  }

  &__content {
    flex: 1;
  }

  &__icon {
    font-size: 20px;
    margin-right: 10px;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
  }
}