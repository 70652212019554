/*  ==========================================================================
    TEXT PIC BOX
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__text-pic-box--bg-primary: $clr__ci--primary;
$module-clr__text-pic-box--bg-secondary: $clr__ci--secondary;
$module-clr__text-pic-box--bg-tertiary: $clr__ci--tertiary;
$module-clr__text-pic-box--typo-primary: $clr__typo--secondary;
$module-clr__text-pic-box--typo-secondary: $clr__typo--secondary;
$module-clr__text-pic-box--typo-tertiary: $clr__typo--primary;
$module-clr__text-pic-box--accent-primary: $clr__ci--secondary;
$module-clr__text-pic-box--accent-secondary: $clr__typo--secondary;
$module-clr__text-pic-box--accent-tertiary: $clr__ci--primary;

/*
 * MODULE
 */

.bn-text-pic-box {
  .bn-text-pic-box__frame {
    justify-content: space-between;
    .bn-text-pic-box__content,
    .bn-text-pic-box__images {
      align-items: flex-start;
      display: flex;
      max-width: calc(50% - 10px);
      > div {
        width: 100%;
      }
    }
    .bn-text-pic-box__content {
      padding: 80px 40px 40px 40px;
      .bn-text-pic-box__headline {
        margin-bottom: 40px;
      }
      .bn-text-pic-box__text {
        @include typo--table-lock();
      }
      .bn-text-pic-box__button {
        margin-top: 20px;
      }
    }
    .bn-text-pic-box__images {
      overflow: hidden;
      > div {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -20px;
      }
      .bn-text-pic-box__image {
        display: block;
        padding-top: 20px;
        img {
          @include img--responsive();
        }
        > div {
          overflow: hidden;
        }
      }
      // output according to number of pictures
      &.bn-text-pic-box__images--number-1 {
        .bn-text-pic-box__image {
          width: 100%;
        }
      }
      &.bn-text-pic-box__images--number-2,
      &.bn-text-pic-box__images--number-3 {
        .bn-text-pic-box__image {
          width: calc(50% - 10px);
          &:first-child {
            width: 100%;
          }
        }
      }
      &.bn-text-pic-box__images--number-4,
      &.bn-text-pic-box__images--number-5,
      &.bn-text-pic-box__images--number-6 {
        .bn-text-pic-box__image {
          width: calc(50% - 10px);
        }
      }
    }
  }
  // colors
  &.bn-text-pic-box--clr-primary {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        background-color: $module-clr__text-pic-box--bg-primary;
        .bn-text-pic-box__headline {
          @include typo--subheadline($module-clr__text-pic-box--typo-primary);
        }
        .bn-text-pic-box__text {
          @include typo--rte($module-clr__text-pic-box--typo-primary,$module-clr__text-pic-box--accent-primary);
        }
        .bn-text-pic-box__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box--typo-primary,$module-clr__text-pic-box--accent-primary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box--clr-secondary {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        background-color: $module-clr__text-pic-box--bg-secondary;
        .bn-text-pic-box__headline {
          @include typo--subheadline($module-clr__text-pic-box--typo-secondary);
        }
        .bn-text-pic-box__text {
          @include typo--rte($module-clr__text-pic-box--typo-secondary,$module-clr__text-pic-box--accent-secondary);
        }
        .bn-text-pic-box__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box--typo-secondary,$module-clr__text-pic-box--accent-secondary);
          }
        }
      }
    }
  }
  &.bn-text-pic-box--clr-tertiary {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        background-color: $module-clr__text-pic-box--bg-tertiary;
        .bn-text-pic-box__headline {
          @include typo--subheadline($module-clr__text-pic-box--typo-tertiary);
        }
        .bn-text-pic-box__text {
          @include typo--rte($module-clr__text-pic-box--typo-tertiary,$module-clr__text-pic-box--accent-tertiary);
        }
        .bn-text-pic-box__button {
          .bn-button {
            @include button--text($module-clr__text-pic-box--typo-tertiary,$module-clr__text-pic-box--accent-tertiary);
          }
        }
      }
    }
  }
  // mirror
  &.bn-text-pic-box--mirror {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        order: 2;
      }
      .bn-text-pic-box__images {
        order: 1;
        // output according to number of pictures
        &.bn-text-pic-box__images--number-2,
        &.bn-text-pic-box__images--number-5 {
          .bn-text-pic-box__image {
            &:last-child {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  // spacing to next text pic element
  + .bn-text-pic-box {
    margin-top: 20px;
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-text-pic-box {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        .bn-text-pic-box__headline {
          margin-bottom: 20px;
        }
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-text-pic-box {
    .bn-text-pic-box__frame {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .bn-text-pic-box__content,
      .bn-text-pic-box__images {
        display: block;
        max-width: 100%;
      }
      .bn-text-pic-box__content {
        padding-left: 40px;
        padding-right: 40px;
      }
      .bn-text-pic-box__images {
        > div {
          margin-top: 0;
        }
        .bn-text-pic-box__image {
          display: none;
          padding-top: 10px;
          width: calc(50% - 5px) !important;
          &:nth-child(-n+2){
            display: block;
          }
          &:first-child:nth-last-child(1) {
            width: 100% !important;
          }
        }
      }
    }
    // spacing to next text pic element
    + .bn-text-pic-box {
      margin-top: 10px;
    }
  }
}

// phone
@media #{$mq__phone} {
  .bn-text-pic-box {
    .bn-text-pic-box__frame {
      .bn-text-pic-box__content {
        padding: 20px;
      }
    }
  }
}