/*  ==========================================================================
    ROOMS
    ========================================================================== */

// detail
@mixin rooms--detail($accent,$bg-price,$description,$headline,$suheadline,$typo-price,$bg-visuals,$typo-visuals) {
  .bn-room-box {
    .bn-room-box__wrapper {
      display: flex;
      @media #{$mq__tablet} {
        flex-direction: column;
      }
    }
    .booking,
    .d21-trigger-ibe {
      @include button--cta-primary();
    }
    .bn-room-buttons {
      display: flex;
      &.--right {
        justify-content: flex-end;
      }
    }

    .bn-room-box__wrapper > :first-child {
      margin-right: 40px;
      flex-basis: 45%;
      flex-grow: 100;
    }
    .bn-room-box__wrapper > :last-child {
      margin-left: 10px;
      flex-basis: 55%;
      flex-grow: 100;
      padding-right: 5px;
    }
    .bn-button.bn-toggleimage{
      @include button--default();
      height: 40px;
      line-height: 37px;
      margin-bottom: 10px;
      border-radius: 25px;
    }
    .bn-room-box__images {
      position: relative;
      &.bn-hidden {
        display: none;
      }
      .bn-room-box__slider-container-wrapper {
        position: relative;
      }
      .bn-room-box__slider-container {
        @include aspect-ratio(16, 9);
        background-color: $clr__black;
        position: relative;
        margin-bottom: 50px;
        .bn-room-box__slider {
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
          .bn-room-box__image {
            overflow: hidden;
            img {
              @include img--responsive();
            }
          }
        }
        .bn-room-box__arrows {
          @include slider--arrows($clr__ci--primary);
          .bn-icon {
            font-size: 28px !important;
          }
          left: 0;
          padding: 10px;
          z-index: 5;
          .bn-arrows__arrow {
            margin-right: 10px;
            float: none !important;
            display: inline-block;
          }
        }
        .bn-room-box__dots {
          @include slider--dots($clr__white,6);
          bottom: 20px;
          left: 20px;
          position: absolute;
          width: 100%;
          z-index: 5;
        }
      }
      .bn-room-box__visuals {
        bottom: -30px;
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;
        right: 0px;
        .bn-room-box__visual {
          display: block;
          width: 60px;
          margin-right: 10px;
          a {
            background-color: $bg-visuals;
            border-radius: 100%;
            color: $typo-visuals;
            border: 1px solid;
            height: 60px;
            display: block;
            text-align: center;
            transition: 0.3s;
            .bn-icon {
              font-size: 3.5em;
              line-height: 60px;
            }
            &:visited {
              color: $typo-visuals;
            }
            &:hover {
              background-color: $typo-visuals;
              color: $bg-visuals;
            }
          }
        }
      }
      .bn-room-box__price {
        background-color: $bg-price;
        color: $typo-price;
        height: 110px;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 110px;
        z-index: 10;
        > div {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          padding: 10px;
          position: relative;
          text-align: center;
        }
        .bn-room-box__amount-addition {
          font-size: 1.2em;
        }
        .bn-room-box__amount {
          font-size: 2.2em;
        }
      }
    }
    .bn-room-box__content, .bn-room-box__wrapper {
      .bn-room-box__size {
        margin-top: 20px;
        span {
          font-size: 1.6em;
        }
        .bn-icon {
          font-size: 1.4em;
          margin-left: 5px;
        }
      }
      .bn-room-box__name {
        @include typo--headline($headline);
        margin-top: 10px;
        a {
          text-decoration: none;
        }
      }
      .bn-room-box__subtitle {
        @include typo--subheadline($suheadline);
      }
      .bn-room-box__description,
      .bn-room-box__information,
      .bn-room-box__features {
        margin-top: 20px;
      }
      .bn-room-box__description,
      .bn-room-box__information {
        @include typo--rte($description,$accent);
        font-size: $typo__fs--text;
        * {
          font-size: 1.0em !important;
        }
      }
      .bn-room-box__features {
        background-color: #e5ebee;
        margin-bottom: 15px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        overflow: auto;
        .bn-arrows__arrow {
          margin: 12px;
          font-size: 24px;
          color: $clr__ci--primary;
          cursor: pointer;
          &:hover {
            color: lighten($clr__ci--primary, 10%);
          }
        }
        .bn-room-box__feature {
          padding: 20px 10px;
          text-align: center;
          display: inline-block;
          flex-direction: column;
          .bn-icon {
            color: $clr__ci--primary;
            width: 70px;
            height: 70px;
            margin: 0 auto 10px;
            background-color: white;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
            font-size: 5em;
          }
          span {
            margin-top: 15px;
            font-size: $typo__fs--text;
          }
        }
      }
      .bn-room-box__rates,
      .bn-room-box__inquiry,
      .bn-room-box__buttons {
        margin-top: 40px;
      }
      .bn-room-box__rates {
        @include price-list--v1($description,$accent);
      }
      .bn-room-box__inquiry {
        .bn_request_variation__vertical {
          @include request--v1();
        }
      }
      .bn-room-box__buttons {
        display: flex;
        flex-wrap: wrap;
        > div {
          align-items: flex-end;
          display: flex;
          justify-content: space-between;
          max-width: 33.33%;
          padding-left: 40px;
          text-align: right;
          width: 100%;
          &:first-child {
            padding-left: 0;
            text-align: left;
          }
          &:nth-child(2),
          &:nth-child(3){
            display:none;
          }
          .bn-button {
            &.bn-button--back {
              color: $accent;
              font-size: 1.4em;
              text-decoration: none;
              &:visited {
                color: $accent;
              }
              &:hover {
                color: rgba($accent, 0.5);
              }
              .bn-icon {
                margin-right: 5px;
              }
            }
            &.bn-button--inquire,
            &.bn-button--book {
              min-width: 0 !important;
              width: 100%;
            }
            &.bn-button--inquire {
              @include button--cta-inverted();
            }
            &.bn-button--book {
              @include button--cta-primary();
            }
          }
        }
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // tablet
  @media #{$mq__tablet} {
    .bn-room-box {
      .bn-room-box__images {
        .bn-room-box__price {
          height: 125px;
          min-width: 125px;
          right: 20px;
          top: 20px;
          .bn-room-box__amount {
            font-size: 2.0em;
          }
        }
      }
      .bn-room-box__content {
        .bn-room-box__features {
          .bn-room-box__feature {
            width: 50%;
          }
        }
        .bn-room-box__buttons {
          > div {
            padding-left: 20px;
          }
        }
      }
    }
  }
  // phablet
  @media #{$mq__phablet} {
    .bn-room-box {
      .bn-room-box__images {
        .bn-room-box__visuals {
          @include clearfix();
          .bn-room-box__visual {
            float: left;
            margin-left: 5px;
            margin-top: 0;
            width: 50px;
            a {
              height: 50px;
              .bn-icon {
                font-size: 2.5em;
                line-height: 50px;
              }
            }
          }
        }
        .bn-room-box__price {
          height: auto;
          min-width: 0;
          position: static;
          right: auto;
          top: auto;
          > div {
            display: block;
            height: auto;
          }
          .bn-room-box__amount-addition,
          .bn-room-box__amount {
            display: inline-block;
          }
          .bn-room-box__amount-addition {
            margin: 0 5px;
          }
        }
      }
      .bn-room-box__content {
        .bn-room-box__features {
          padding-left: 0;
          padding-right: 0;
          .bn-room-box__feature {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
          }
        }
        .bn-room-box__buttons {
          > div {
            margin-bottom: 20px;
            max-width: 100%;
            padding-left: 0;
            &:first-child {
              margin-bottom: 0;
              order: 3;
            }
          }
        }
      }
    }
  }
  // phone
  @media #{$mq__phone} {
    .bn-room-box {
      .bn-room-box__images {
        .bn-room-box__slider-container {
          .bn-room-box__arrows {
            display: none;
          }
          .bn-room-box__dots {
            bottom: 10px;
            left: 10px;
          }
        }
        .bn-room-box__visuals {
          left: 10px;
          top: 10px;
          .bn-room-box__visual {
            width: 40px;
            a {
              height: 40px;
              .bn-icon {
                font-size: 2.0em;
                line-height: 40px;
              }
            }
          }
        }
      }
      .bn-room-box__content {
        .bn-room-box__buttons {
          > div {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}