/*  ==========================================================================
    BUTTONS
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__buttons--bg: $clr__ci--primary;
$module-clr__buttons--border: $clr__ci--primary;
$module-clr__buttons--text: $clr__white;
$module-clr__buttons--bg-hover: transparent;
$module-clr__buttons--border-hover: $clr__ci--primary;
$module-clr__buttons--text-hover: $clr__ci--primary;

/*
 * MODULE
 */

.bn-buttons {
  > .bn-grid__row {
    > .bn-grid__col {
      text-align: center;
      > div {
        display: inline-block;
        padding: 5px;
      }
    }
  }
  .bn-button {
    @include button--custom($module-clr__buttons--bg,$module-clr__buttons--border,$module-clr__buttons--text,$module-clr__buttons--bg-hover);
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-buttons {
    > .bn-grid__row {
      > .bn-grid__col {
        > div {
          display: block;
          padding: 10px 0 0 0;
        }
      }
    }
  }
}