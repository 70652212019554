.dna-seaside-background-signet__positioner {
  margin: 0px 100px;
  position: relative;
  pointer-events: none;
  z-index: -1;
  &.dna-seaside-background-signet__positioner--left {
    &::before {
      left: 0;
      transform: translateY(-46%) translateX(-50%);
      @media #{$mq__desktop--medium} {
        transform: translateY(-46%) translateX(-35%);
      }
      @media #{$mq__desktop--large} {
        transform: translateY(-46%) translateX(-30%);
      }
    }
  }
  &.dna-seaside-background-signet__positioner--right {
    &::before {
      right: 0;
      transform: translateY(-46%) translateX(50%);
      @media #{$mq__desktop--medium} {
        transform: translateY(-46%) translateX(35%);
      }
      @media #{$mq__desktop--large} {
        transform: translateY(-46%) translateX(30%);
      }
    }
  }
  &::before {
    position: absolute;
    font-size: 980px;
    color: rgba(0, 62, 84, 0.10);
    @media #{$mq__desktop--small} {
      font-size: 640px;
    }
    @media #{$mq__tablet} {
      font-size: 520px;
    }
    @media #{$mq__phone} {
      font-size: 360px;
    }
  }
}
